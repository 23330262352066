// i18next-extract-mark-ns-start amc-amazon-marketing-cloud
import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/Seo";
import SplitFeature from "../components/SplitFeature";
import "../components/VideoAdsPage/video-ads-page.scss";
import Layout from "../containers/Layout";
import HeroSection from "../components/HeroSection";
import { IconType } from "../components/Icon";
import GradientSection from "../containers/GradientSection";
import Section from "../containers/Section";
import GradientTitle from "../components/GradientTitle";
import { HeaderVariant } from "../components/Header";
import Paragraph from "../components/Paragraph";
import AmazonCloud from "../images/amazon/amazon-cloud.svg";
import AmazonCloudDiagram from "../images/amazon/amazon-cloud-diagram.svg";
import DownArrowLarge from "../images/down-arrow-large.svg";
import PricingCard from "../components/PricingCard";
import BottomCTA from "../components/BottomCta";
import { Category, Name, Type, CTA_ID } from "../utils/analytics/constants";
import { nav } from "../utils/navigation";
import { useSegment, trackView } from "../utils/analytics";
import { graphql } from "gatsby";
import { Trans, useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import FormModal from "../components/FormModal";
import { PAGE } from "../constants/pages";

interface AMCProps {
  data: any;
}

const AMC: React.FC<AMCProps> = ({ data }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const cta = {
    text: t("Book a data consult"),
    route: t("/demo/")
  };
  const [activeDataConsultModal, setActiveDataConsultModal] = useState<{
    variant: string;
    isOpen: boolean;
  }>(null);
  const { segment, helpers } = useSegment();

  const pageData = data;

  const featureSections = [
    {
      title: t("Acquiring, keeping and valuing your customers"),
      imageReplacement: (
        <StaticImage
          className="lg:ml-12 mt-6"
          width={580}
          alt=""
          src="../images/amazon/amazon-cloud-noise.png"
        />
      ),
      reverse: true,
      features: [
        {
          text: t("How loyal are my customers to my brand?  When do they come back and repurchase items?"),
          icon: IconType.capital
        },
        {
          text: t("How much does it cost to acquire a new customer on Amazon?"),
          icon: IconType.diamond
        },
        {
          text: t("What is the Lifetime Value of each Amazon customer?"),
          icon: IconType.processing
        }
      ]
    },
    {
      title:
        t("Attribute all advertising spend from the first impression to the purchase"),
      imageReplacement: (
        <StaticImage
          className="lg:ml-12 mt-6"
          width={580}
          alt=""
          src="../images/amazon/amc-tools.png"
        />
      ),
      features: [
        {
          text: t("Will a mixture of campaign exposure help win a new-to-brand customer?  What is the right media mix?"),
          icon: IconType.megaphone
        },
        {
          text: t("Which Upper Funnel Ads are influencing users to purchase from Sponsored Product or Display Campaigns?"),
          icon: IconType.dspBlack
        },
        {
          text: t("Will additional platforms, such as my website, drive conversions after Ad exposure on or off Amazon?"),
          icon: IconType.language
        }
      ]
    },
    {
      title: t("Measure brand building strategies"),
      imageReplacement: (
        <StaticImage
          className="lg:ml-12 mt-6"
          width={580}
          alt=""
          src="../images/amazon/amc-funnel.png"
        />
      ),
      reverse: true,
      features: [
        {
          text: t("Understand which branded search terms are coming from which ADSP campaigns at what time of day"),
          icon: IconType.funnelBlack
        },
        {
          text: t("Know exactly which new audiences you are reaching and where they end up converting when launching Upper Funnel campaigns."),
          icon: IconType.funnelBlack
        },
        {
          text: t("What other Ad Units are the most impactful in guiding a new-to-brand user to purchase after being exposed to an Upper Funnel campaign?"),
          icon: IconType.funnelBlack
        }
      ]
    }
  ];

  const pricingData = [
    {
      title: "Starter",
      displayedTitle: t("Starter 🌱"),
      report: t("1 Dashboard"),
      cta: {
        text: t("Book a data consult"),
        route: ""
      },
      pricingFeatures: [
        {
          text: t("1 hour consultation to review advertising strategy and understand your KPIs"),
          iconType: IconType.checkmark
        },
        { text: t("Custom AMC Dashboard"), iconType: IconType.checkmark },
        { text: t("+ additional hours for a fee"), iconType: IconType.checkmark }
      ]
    },
    {
      title: "Premium",
      displayedTitle: t("Premium 💎"),
      report: t("4 Dashboards ~ 1/Quarter"),
      cta: {
        text: t("Book a data consult"),
        route: ""
      },
      pricingFeatures: [
        {
          text: t("4 quarterly consultations (1 hour each)"),
          iconType: IconType.checkmark
        },
        {
          text: t("Custom AMC Dashboard"),
          iconType: IconType.checkmark
        },
        {
          text: t("4 hours of custom query work per quarter"),
          iconType: IconType.checkmark
        },
        { text: t("+ additional hours for a fee"), iconType: IconType.checkmark }
      ]
    },
    {
      title: "Enterprise",
      displayedTitle: t("Enterprise 🌎"),
      report: t("Custom Dashboards"),
      cta: {
        text: t("Book a data consult"),
        route: ""
      },
      pricingFeatures: [
        {
          text: t("Private Consulting"),
          iconType: IconType.checkmark
        },
        {
          text: t("Custom AMC Dashboards"),
          iconType: IconType.checkmark
        },
        {
          text: t("Project-based Pricing"),
          iconType: IconType.checkmark
        }
      ]
    }
  ];

  return (
    <Layout primaryButtonId={CTA_ID.getStartedNav.id}>
      {/* Wating on transaltions for this */}
      <SEO
        title={t("Amazon Marketing Cloud (AMC) Solution by Perpetua")}
        description={
          t("Discover how Perpetua integrates with Amazon Marketing Cloud (AMC) to support brands and agencies understand the full impact of their advertising efforts by leveraging advanced reporting created by Perpetua’s proprietary queries.")
        }
        image={`https://perpetua.io${pageData?.openGraphImage?.publicURL}`} //TODO Translate this
      />
      <HeroSection
        page={PAGE.AMC}
        bgGradient="bg-white"
        gradientTitle={[t("Advanced analytics via"), t("Amazon Marketing Cloud")]}
        gradientPosition={2}
        h1Title={t("Amazon Marketing Cloud")}
        description={
          t("By leveraging Amazon Marketing Cloud, Perpetua is creating proprietary insights available only for our customers, allowing you to finally understand the full impact of your advertising.")
        }
        icon={IconType.cloud}
        cta={cta}
        dataConsultModal={setActiveDataConsultModal}
        badge={
          <StaticImage
            className="md:ml-12 mt-6"
            width={100}
            alt=""
            src="../images/amazon/amazon-partner.png"
          />
        }
        imageReplacement={<AmazonCloud className="w-full md:h-full h-1/2 " />}
      />
      <GradientSection className="mt-12 mb-12 md:mt-24 md:mb-0 rounded-tl-large bg-gradient-to-br from-purple-2 via-white to-white sm:pt-24 md:pb-22">
        {/* First section */}
        <div className="px-0 flex flex-col items-start sm:items-center sm:max-w-2xl sm:mx-auto">
          <AmazonCloudDiagram className="my-8 h-[200px] w-[200px] sm:my-0 sm:h-auto sm:w-auto" />
          <GradientTitle
            title={
            language === "de" ? //ideally this would be done using interpolation to use the gradient position
              ["Die",
              "Amazon Marketing Cloud",
              "verschafft dir einen umfassenden Überblick"]
              :
              [t("View the full picture with the power of"),
              t("Amazon Marketing Cloud")]
            }
            gradientPosition={2}
            variant={HeaderVariant.h3}
            color="purple"
            lineBreak={false}
            className=" font-bold sm:text-center md:text-5xl"
          />
          <div className="sm:px-12 mb-16">
            <Paragraph className="my-12 sm:text-center">
              <span className="font-semibold">
                {t("Meeting the many data security compliance laws and regulations required by industry, state, and federal data regulations can be difficult. With the secure environment hosted by Amazon with Amazon Marketing Cloud (AMC), this critical data is available for pulling advanced insights all while following these regulations.")}
              </span>
            </Paragraph>
            <Paragraph className="sm:text-center">
              {t("Reports are fully customizable based on the intricacies of your business, here are some of the metrics you can expect to see")}
            </Paragraph>
          </div>
          <DownArrowLarge className="ml-auto sm:mx-auto" />
        </div>
      </GradientSection>
      {featureSections &&
        featureSections?.map(feature => (
          <Section>
            <SplitFeature
              title={feature?.title}
              features={feature?.features}
              reverse={feature?.reverse}
              imageReplacement={feature?.imageReplacement}
            />
          </Section>
        ))}
      <GradientSection className="mt-24 bg-gradient-to-b from-purple-3-light via-white to-white pt-24 md:pb-22">
        <Section>
          <div className="flex flex-col w-full mx-auto items-center">
            <div className="w-full justify-center mb-8">
              <GradientTitle
                title={[t("Flexible"), t("Pricing")]}
                gradientPosition={1}
                variant={HeaderVariant.h2}
                color="purple"
                lineBreak={false}
                className={"text-center lg:text-6xl"}
              />
            </div>
            <div className="flex flex-wrap lg:flex-row gap-10 w-full justify-center mb-20">
              {pricingData?.map((item, index) => {
                return (
                  <PricingCard
                    key={index}
                    variant={item?.title?.toLowerCase()}
                    data={item}
                    className="w-full sm:w-5/12 lg:w-1/4"
                    page={PAGE.AMC}
                    dataConsultModal={setActiveDataConsultModal}
                  />
                );
              })}
            </div>
          </div>
        </Section>
      </GradientSection>
      <BottomCTA
        primaryButtonId={CTA_ID.getStartedBottom.id}
        secondaryButtonId={CTA_ID.demoBottom.id}
        title={"Getting started is easy"}
        subtitle={
          t("Everything you need to grow your ecommerce business, in one platform.")
        }
        primaryButtonText={t("Get Started")}
        primaryButtonOnClick={() => {
          helpers
            .delayTrack(Name.Cta, {
              category: Category.ButtonClick,
              type: Type.Button,
              text: t("Get Started"),
              page_url: window?.location.href,
              destination: "https://app.perpetua.io/signup",
              location: t("Amazon Marketing Cloud")
            })
            .then(() => nav(t("https://app.perpetua.io/signup")));
        }}
        secondaryButtonText={t("Request a Demo")}
        secondaryButtonOnClick={() => {
          trackView(Name.Cta, {
            category: Category.ButtonClick,
            type: Type.Button,
            text: t("Request a Demo"),
            page_url: window?.location.href,
            destination: t("/demo/"),
            location: t("Amazon Marketing Cloud")
          });
          nav(t("/demo/"));
        }}
      />
      {activeDataConsultModal?.isOpen && (
        <FormModal
          setActiveDataConsultModal={setActiveDataConsultModal}
          title={t("We just need a bit of info first")}
          data={activeDataConsultModal}
        />
      )}
    </Layout>
  );
};

export default AMC;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    openGraphImage: file(relativePath: { eq: "amazon/amc-openGraph.png" }) {
      publicURL
    }
  }
`;
